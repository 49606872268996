/** slick.scss */
/* Slider */

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }

  .slick-loading & {
    visibility: hidden;
  }
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  [dir="rtl"] & {
    float: right;
  }
  img {
    display: block;
  }
  &.slick-loading img {
    display: none;
  }

  display: none;

  &.dragging img {
    pointer-events: none;
  }

  .slick-initialized & {
    display: block;
  }

  .slick-loading & {
    visibility: hidden;
  }

  .slick-vertical & {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
}
.slick-arrow.slick-hidden {
  display: none;
}

.product-slick-slider-dots {
  margin-top: 5px;
  margin-left: -5px;
  margin-right: -5px;
  padding: 0px;
  display: flex;
  justify-content: center;
  li {
    list-style: none;
    display: inline-block;
    max-width: 112px;
    max-height: 56px;
    margin: 5px;

    img {
      opacity: 0.5;
      width: 80%;
      height: auto;
    }

    &.slick-active {
      img {
        opacity: 1;
      }
    }
  }
}

// Product Gallery v2 slider

.controls {
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  left: 0;
  font-size: 18px;
  width: 100%;
  top: 50%;
  transform: translatey(-50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 768px) {
    position: absolute;
    bottom: -50px;
    justify-content: center;
    top: inherit;
  }
  &:focus {
    outline: none;
  }
  li {
    cursor: pointer;
    &.prev {
      position: relative;
      background-color: var(--#{$prefix}white);
      height: 40px;
      width: 40px;
      margin-left: -50px;
      border-radius: 50%;
      line-height: 40px;
      text-align: center;
      border: 1px solid var(--#{$prefix}border-color);
      transition: 0.2s ease-in;
      color: var(--#{$prefix}gray-400);
      @media (max-width: 768px) {
        margin-right: 5px;
      }
      &:hover {
        background-color: var(--#{$prefix}primary);
        color: var(--#{$prefix}white);
        border-color: var(--#{$prefix}primary);
      }
    }
    &.next {
      position: relative;
      background-color: var(--#{$prefix}white);
      height: 40px;
      width: 40px;
      margin-right: -50px;
      border-radius: 50%;
      line-height: 40px;
      text-align: center;
      border: 1px solid var(--#{$prefix}border-color);
      transition: 0.2s ease-in;
      color: var(--#{$prefix}gray-400);
      @media (max-width: 768px) {
        margin-left: 5px;
      }
      &:hover {
        background-color: var(--#{$prefix}primary);
        color: var(--#{$prefix}white);
        border-color: var(--#{$prefix}primary);
      }
    }
  }
}
.controls-testimonial {
  position: absolute;
  bottom: -100px;
  @media (max-width: 990px) {
    bottom: -50px;
  }
  justify-content: center;
  top: inherit;
}

// Ecommerce product tools

.product-tools {
  position: relative;
  .thumbnails {
    margin: 10px 0;
    text-align: center;
  }
  img {
    border-radius: 6px;
    max-width: 100%;
  }
}

.product {
  .tns-item {
    Image {
      border-radius: 6px;
      width: 100%;
    }
  }
}

.thumbnails {
  li {
    Image {
      height: auto;
    }
  }
  .tns-nav-active {
    .thumbnails-img {
      background: none;
      border: 2px solid var(--#{$prefix}primary);
      border-radius: 0.5rem;
    }
  }
  .nav-active {
    .thumbnails-img {
      background: none;
      border: 2px solid var(--#{$prefix}primary);
      border-radius: 0.5rem;
    }
  }
}
